/* eslint-disable */
import i18n from 'i18n';
import dateFnsFormat from 'date-fns/format';
import isValid from 'date-fns/isValid';
import intervalToDuration from 'date-fns/intervalToDuration';
import { sortTicketOptions } from '@lux/spa/config';
import { enGB, ru, et, lt, lv } from 'date-fns/locale';

const locales = { en: enGB, ru, et, lt, lv };

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
export const localizedDate = (date, formatStr = 'PP') => {
  const isValidDate = /^\d{2,4}-\d{2,4}-\d{2,4}$/.test(date);

  if (isValidDate) {
    return 'Invalid Date';
  }

  return dateFnsFormat(new Date(date), formatStr, {
    locale: locales[i18n.language],
  });
};

export const dateFormatForExponea = (
  date,
  formatStr = "yyyy-MM-dd'T'HH:mm:ssxxx"
) => {
  const newDate = new Date(date);

  if (!isValid(newDate)) return 'Invalid Date';

  return dateFnsFormat(newDate, formatStr);
};

export const extractContent = (s, space) => {
  const span = document.createElement('span');
  span.innerHTML = s;
  if (space) {
    const children = span.querySelectorAll('*');
    for (let i = 0; i < children.length; i++) {
      if (children[i].textContent) {
        children[i].textContent += ' ';
      } else {
        children[i].innerText += ' ';
      }
    }
  }
  return [span.textContent || span.innerText].toString().replace(/ +/g, ' ');
};

export const addBlankAttrToHtml = (text = '') =>
  text.replace(/href/g, 'target="_black" href');

export const organizeStops = (legs, showTime) => {
  const stops = [];
  let transfersCount = 1;
  const legsCount = legs.length;

  if (!legsCount) return stops;

  legs.sort((a, b) => a.OrderNumber - b.OrderNumber);

  legs.forEach((leg, key) => {
    const isLastLeg = legsCount === key + 1;
    const isEqualStops =
      key + 1 < legsCount &&
      leg.DestinationStopId === legs[key + 1].DepartureStopId;

    stops.push({
      stopName: leg.DepartureStopName,
      country: leg.DepartureStopLocation.CountryName,
      city: leg.DepartureStopLocation.CityName,
      time: showTime(leg.DepartureDatetime),
      address: leg.DepartureStopName,
      transfer: !!key, // for remove last !isLastLeg && !!key
      isIntermediat: false,
    });

    leg.IntermediateStops.map(stop => {
      stops.push({
        stopName: stop.StopName,
        country: stop.StopLocation.CountryName,
        city: stop.StopLocation.CityName,
        time: showTime(stop.DepartureDateTime),
        address: stop.StopName,
        transfer: false,
        isIntermediat: true,
      });
    });

    if (!isEqualStops) {
      ++transfersCount;
      stops.push({
        stopName: leg.DestinationStopName,
        country: leg.DestinationStopLocation.CountryName,
        city: leg.DestinationStopLocation.CityName,
        time: showTime(leg.ArrivalDatetime),
        address: leg.DestinationStopName,
        transfer: !isLastLeg,
        isIntermediat: false,
        // transfer: true,
      });
    }
  });

  return stops;
};

export const organizeStopsNew = (legs, showTime) => {
  const stops = [];
  const legsCount = legs.length;

  if (!legsCount) return stops;

  legs.sort((a, b) => a.OrderNumber - b.OrderNumber);

  legs.forEach((leg, key) => {
    stops.push({
      from: leg.DepartureStopName,
      to: leg.DestinationStopName,
      stopName: leg.DestinationStopName,
      country: leg.DepartureStopLocation.CountryName,
      city: leg.DepartureStopLocation.CityName,
      fromTime: showTime(leg.DepartureDatetime),
      toTime: showTime(leg.ArrivalDatetime),
      transfer: key > 0,
      busEquipment: leg?.BusEquipment || [],
      brandName: leg?.BrandName,
      busCompanyName: leg?.BusCompanyName,
      isPartner: !leg?.BrandName?.startsWith('Lux'),
      intermediateStops: leg.IntermediateStops.map(stop => {
        return {
          stopName: stop.StopName,
          time: showTime(stop.DepartureDateTime),
        };
      }),
      interval:
        key === 0
          ? null
          : intervalToDuration({
              start: new Date(legs[key - 1].ArrivalDatetime),
              end: new Date(leg.DepartureDatetime),
            }),
    });
  });

  return stops;
};

export const updatePrices = (prices, products) => {
  const updatedProducts = {
    ...products,
  };

  prices.forEach(item => {
    item.OutboundLegs.forEach(leg => {
      let { SeatInfo } = updatedProducts.OutboundJourneys[0].Legs[
        leg.OrderNumber - 1
      ];

      SeatInfo = SeatInfo.map(info => {
        const prices = leg.Prices.filter(
          price => info.IsBusinessClass === price.IsBusinessClass
        );

        const hasCampaign = prices.some(price => price.IsCampaign);

        return item.ProductId === info.ProductId
          ? {
              ...info,
              HasCampaign: hasCampaign,
              IsCampaign: hasCampaign ? info.IsCampaign ?? hasCampaign : null,
              Prices: leg.Prices,
            }
          : info;
      });

      updatedProducts.OutboundJourneys[0].Legs[
        leg.OrderNumber - 1
      ].SeatInfo = SeatInfo;
    });

    if (item.InboundLegs.length) {
      item.InboundLegs.forEach(leg => {
        let { SeatInfo } = updatedProducts.InboundJourneys[0].Legs[
          leg.OrderNumber - 1
        ];

        SeatInfo = SeatInfo.map(info => {
          const prices = leg.Prices.filter(
            price => info.IsBusinessClass === price.IsBusinessClass
          );

          const hasCampaign = prices.some(price => price.IsCampaign);

          return item.ProductId === info.ProductId
            ? {
                ...info,
                HasCampaign: hasCampaign,
                IsCampaign: hasCampaign ? info.IsCampaign ?? hasCampaign : null,
                Prices: leg.Prices,
              }
            : info;
        });

        updatedProducts.InboundJourneys[0].Legs[
          leg.OrderNumber - 1
        ].SeatInfo = SeatInfo;
      });
    }
  });

  return updatedProducts;
};

export const updatePassengersPrices = (passengers, products) => {
  const pass = products.OutboundJourneys[0].Products;

  const passengersPrices = pass.map(p => {
    return passengers.filter(item => item.ProductId === p.ProductId)[0]
      .TotalPrice;
  });

  return passengersPrices;
};

export const updatePassengersPricesV2 = (
  products,
  outboundPassengers = [],
  inboundPassengers = []
) => {
  const pass = products.OutboundJourneys[0].Products;

  let passengerPrices = [];
  let passengersFee = 0;

  if (inboundPassengers?.[0]?.Products?.length) {
    passengerPrices = pass.map(pass => {
      let inboundSalesFee = 0;
      let outboundSalesFee = 0;
      const inboundPass = inboundPassengers[0].Products.filter(inboundPass => {
        if (
          inboundPass.ProductId === pass.ProductId &&
          inboundPass?.SalesFee?.Price
        ) {
          inboundSalesFee = inboundPass.SalesFee.Price;
          passengersFee += inboundPass.SalesFee.Price;
        }
        return inboundPass.ProductId === pass.ProductId;
      });

      const outboundPass = outboundPassengers[0].Products.filter(
        outboundPass => {
          if (
            outboundPass.ProductId === pass.ProductId &&
            outboundPass?.SalesFee?.Price
          ) {
            outboundSalesFee = outboundPass.SalesFee.Price;
            passengersFee += outboundPass.SalesFee.Price;
          }
          return outboundPass.ProductId === pass.ProductId;
        }
      );

      return (
        outboundPass[0].TotalPrice -
        outboundSalesFee +
        inboundPass[0].TotalPrice -
        inboundSalesFee
      );
    });
  } else {
    passengerPrices = pass.map(pass => {
      let outboundSalesFee = 0;

      const outboundPass = outboundPassengers[0].Products.filter(
        outboundPass => {
          if (
            outboundPass.ProductId === pass.ProductId &&
            outboundPass?.SalesFee?.Price
          ) {
            outboundSalesFee = outboundPass.SalesFee.Price;
            passengersFee += outboundPass.SalesFee.Price;
          }
          return outboundPass.ProductId === pass.ProductId;
        }
      );

      return outboundPass[0].TotalPrice - outboundSalesFee;
    });
  }

  return { passengerPrices, passengersFee };
};

export const regExpForNames = /^[a-zA-ZаАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/;

export const getCookieByName = name => {
  const value = `; ${document.cookie}`;

  const parts = value.split(`; ${name}=`);
  if (parts.length === 2)
    return parts
      .pop()
      .split(';')
      .shift();
};

export const getCookie = name => {
  // TODO: chagne this function to getCookieByName from utilss
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const removeCookie = (name, path = '/') => {
  if (getCookie(name)) {
    document.cookie = `${name}= ; path=${path}; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
  }
};

export const createThanksSuccessAnaliticsData = ({
  returnDate,
  departDate,
  from,
  to,
  currencyName,
  totalPrice,
  promocode,
  objects,
}) => {
  try {
    let Id = null;
    // const items = [];

    // objects.forEach(object => {
    //   object.Passengers.forEach(pass => {
    //     pass.Tickets.forEach((ticket, key) => {
    //       items.push({
    //         item_id: `${object.DepartureCityName}-${object.DestinationCityName}`,
    //         item_name: `${object.DepartureCityName}-${object.DestinationCityName}`,
    //         currency: 'RUB',
    //         price: ticket.Price,
    //         item_category: `${from.CountryName}-${to.CountryName}`,
    //         quantity: 1,
    //         item_variant: returnDate ? 'return-trip' : 'one-way',
    //         item_brand: `${object.Legs[key].BrandName}`,
    //       });
    //     });
    //   });
    // });

    const products = [];

    objects.forEach(object => {
      object.Passengers.forEach(pass => {
        pass.Tickets.forEach((ticket, key) => {
          products.push({
            id: `${object.DepartureCityName}-${object.DestinationCityName}`,
            name: `${object.DepartureCityName}-${object.DestinationCityName}`,
            price: ticket.Price,
            category: `${from.CountryName}-${to.CountryName}`,
            quantity: 1,
            variant: 'one-way',
            brand: `${object.Legs[key].BrandName}`,
          });
        });
      });
    });

    Id = objects?.[0]?.ShoppingBasketCode;

    return {
      tripType: returnDate ? 'return-trip' : 'one-way',
      departureStopId: from.BusStopId,
      destinationStopId: to.BusStopId,
      currencyCode: currencyName,
      route: `${from.CityName}-${to.CityName}`,
      price: totalPrice,
      promocode: promocode || null,
      departureDestination: `${from.CountryName}-${to.CountryName}`,
      stage: 'purchase',
      event: 'payment-success',
      quantity: products.length,
      Id,
      products,
      departDate: dateFnsFormat(new Date(departDate), 'yyyy-MM-dd'),
      ...(returnDate
        ? {
            returnDate: dateFnsFormat(new Date(returnDate), 'yyyy-MM-dd'),
          }
        : {}),
    };

    return {
      transaction_id: Id,
      currency: 'RUB',
      item_id: `${from.CountryName}-${to.CountryName}`,
      value: totalPrice,
      tax: '?',
    };
  } catch (e) {
    console.error(e);
  }
};

export const round = num => +`${Math.round(`${num}e+2`)}e-2`;

export const compareByTime = (a, b) => {
  const departureA = new Date(a.DepartureDateTime).getTime();
  const departureB = new Date(b.DepartureDateTime).getTime();

  const arrivalA = new Date(a.ArrivalDateTime).getTime();
  const arrivalB = new Date(b.ArrivalDateTime).getTime();

  const departureDiff = departureA - departureB;

  return departureDiff !== 0 ? departureDiff : arrivalA - arrivalB;
};

export const getMinPrice = ticket => {
  const prices = ticket.PriceClasses.map(i => i.Price);
  return Math.min(...prices);
};

const compareByDuration = (a, b) => {
  const durationA =
    new Date(a.ArrivalDateTime).getTime() -
    new Date(a.DepartureDateTime).getTime();
  const durationB =
    new Date(b.ArrivalDateTime).getTime() -
    new Date(b.DepartureDateTime).getTime();

  return durationA - durationB;
};

export const sortTickets = (tickets, setLists, sortType) => {
  if (sortType === sortTicketOptions.transfers.id) {
    const directTickets = tickets.filter(ticket => ticket.Legs.length === 1);
    const transfersTickets = tickets.filter(ticket => ticket.Legs.length >= 2);

    // Sort direct flights by time (by departure and arrival time)
    const sortedDirectTickets = directTickets.sort(compareByTime);

    // Sorting out connecting tickets:
    // First by the number of “legs”, then within each group by departure and arrival time
    const sortedTransfersTickets = transfersTickets.sort((a, b) => {
      const legsDiff = a.Legs.length - b.Legs.length; // Sort by number of legs
      return legsDiff !== 0 ? legsDiff : compareByTime(a, b); // If the legs are the same, sort by departure and arrival time
    });

    const lists = [];
    if (sortedDirectTickets.length > 0) {
      lists.push({ tickets: sortedDirectTickets });
    }
    if (sortedTransfersTickets.length > 0) {
      lists.push({ tickets: sortedTransfersTickets });
    }

    setLists(lists);
  } else if (sortType === sortTicketOptions.time.id) {
    // setLists([{ tickets: [...tickets].sort(compareByTime) }]);
    setLists([{ tickets: [...tickets].sort(compareByDuration) }]);
  } else if (sortType === sortTicketOptions.price.id) {
    setLists([
      {
        tickets: [...tickets].sort((a, b) => {
          const minPriceA = getMinPrice(a);
          const minPriceB = getMinPrice(b);
          return minPriceA - minPriceB;
        }),
      },
    ]);
  } else {
    throw new Error('Invalid sort type');
  }
};
