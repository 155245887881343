/* eslint-disable */
import React, { cloneElement, Children, useEffect, useContext } from 'react';
import cn from 'classnames';
import { useComponentVisible } from '@lux/spa/components';
import { StoreContext } from '@lux/spa/store';
import s from './dropdown.module.scss';


const Dropdown = ({
  isTop = false,
  isRight = false,
  className,
  classNameTarget,
  classNameMenu,
  Target,
  children,
  smallMenu = false,
 }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const childrens = Children.toArray(children);

  const toggleMenu = (e) => {
    e.stopPropagation();
    setIsComponentVisible(!isComponentVisible);
  };


  useEffect(() => {
    dispatch({
      type: 'UPDATE_TICKETS',
      payload: {
        isOpenDropDown: isComponentVisible,
      },
    });
  }, [isComponentVisible]);

  return (
    <div
      className={cn(
        s.dropdown,
        isComponentVisible && s.open,
        isTop && s.top,
        isRight && s.right,
        className
      )}
      ref={ref}
    >
      <Target
        isOpen={isComponentVisible}
        className={cn(s.target, classNameTarget)}
        onClick={!!children ? toggleMenu : () => {}}
      />
      <div className={s.triangleWrap}>
        <div className={s.triangle} />
      </div>
      {!!children && (
        <div className={cn(s.menu, smallMenu && s.smallMenu, classNameMenu)}>
          {childrens.map((child) =>
            cloneElement(child, {
              onClick: () => setIsComponentVisible(false),
              isOpen: isComponentVisible,
            })
          )}
        </div>
      )}
    </div>
  );
};

export default Dropdown;

