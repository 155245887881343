import React, { useState, useContext, useEffect, useRef } from 'react';
import cn from 'classnames';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { Collapse } from 'react-collapse';
import { StoreContext } from '@lux/spa/store';
import { WidthContext } from '@lux/spa/contexts/width-context';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { localizedDate } from 'utils';
import getTime from 'date-fns/getTime';
import { DELETE_BASKET } from '@lux/spa/apollo/mutations';
import { DEFAULT_LANG } from '@lux/spa/config';
import {
  Button,
  Container,
  Countdown,
  Information,
  UpdateBasketTime,
  BasketExpired,
  Loading,
} from '@lux/ui';
import { Stops, StopsNew } from '@lux/spa/components';
import i from '@lux/spa/styles/icons.module.scss';
import { ChangeVoucher } from './components';
import s from './trip-details.module.scss';

const TripDetailsContent = ({
  t,
  className,
  handleContinue,
  notRenderNextButton,
  disabledNextStep = true,
  loading,
  getTickets,
  variables,
  isSearch,
  totalTransferPrice,
  page,
  totalPassPrice,
  language,
  isMobile,
}) => {
  const [state, dispatch] = useContext(StoreContext);

  const {
    inbound = {},
    outbound = {},
    symbol,
    totalPrice,
    basketValidUntil,
    passengerPrices,
    products = {},
    basketTransfers,
    basketTransfersPrice,
    passengersFee,
    vouchers,
    stops,
  } = state.changeTickets;

  const {
    departDate,
    departureDateTime,
    from,
    to,
  } = state.changeTickets.search;
  const { BasketSalesFee = {} } = products;
  const fee = BasketSalesFee?.Price || '';
  const [isOpen, setIsOpen] = useState(false);
  // const [isOpenOutbound, setIsOpenOutbound] = useState(false);
  const [showVoucherInput, setShowVoucherInput] = useState(false);
  const [isOpenInfo, setIsOpenInfo] = useState(true);
  const [redeemVoucherModal, setRedeemVoucherModal] = useState(false);
  // const [isPrice, setIsPrice] = useState(Number(totalPrice) === totalPrice);

  const showTime = time => {
    return time
      ?.split('T')[1]
      ?.split(':')
      ?.slice(0, 2)
      ?.join(':');
  };

  // useEffect(() => {
  //   setIsPrice(Number(totalPrice) === totalPrice);
  // }, [totalPrice]);

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={cn(s.tripDetails, className)}>
      <Countdown
        hours={false}
        days={false}
        className={cn(s.countdown, !basketValidUntil && s.hide)}
        timestamp={basketValidUntil ? getTime(new Date(basketValidUntil)) : 0}
      />
      <div className={s.block}>
        <h3 className={s.title}>{t('trip details')}</h3>
        <div className={cn(s.info, isOpen && s.open)}>
          <div className={s.type}>
            <div className={s.circle} />
            <span>{t('selected trip')}</span>
            {/* {returnDate && !inbound.selected && (
              <Link
                to={{
                  pathname: '/tickets/search/',
                  search: lastSearch,
                }}
                className={s.clear}
                onClick={onClear}
              >
                {t('clear')}
              </Link>
            )} */}
          </div>
          <h4 onClick={stops.length ? toggleIsOpen : () => {}}>
            {departDate &&
              localizedDate(new Date(departDate), 'eee, d LLLL yyyy')}
            {Boolean(stops.length) && <i className={i.arrowBottom} />}
          </h4>
          <div className={s.details}>
            {/* <span>{showTime(outbound.departureDateTime)}</span> */}
            {` ${t(from.CityName)}, ${t(from.CountryName)} - `}
            {/* <span>{showTime(outbound.arrivalDateTime)}</span> */}
            {` ${t(to.CityName)}, ${t(to.CountryName)}`}
          </div>

          <Collapse isOpened={isOpen}>
            <div className={s.stops}>
              <StopsNew stops={stops} likeMobile showAllStops />
            </div>
          </Collapse>
        </div>
      </div>
      {!isSearch
        ? Number(totalPrice) === totalPrice && (
            <>
              <div
                className={cn(
                  s.block,
                  (page === 'payment' || !!vouchers.approved.length) &&
                    s.voucherBlock
                )}
              >
                {page === 'payment' &&
                  !isMobile &&
                  !vouchers.fullyСoversСost && (
                    <ChangeVoucher className={s.voucher} />
                  )}
                {!!vouchers.approved.length &&
                  vouchers.approved.map((voucher, key) => {
                    const isLast = vouchers.approved.length === key + 1;
                    return (
                      <div className={s.approvedVoucher} key={voucher.code}>
                        <i className={i.success} />
                        <div className={s.voucherCode}>{voucher.code}</div>
                        <div className={s.count}>
                          -{' '}
                          {isLast && vouchers.fullyСoversСost
                            ? voucher.value -
                              (vouchers.totalVouchersValue - totalPrice)
                            : voucher.value}{' '}
                          {symbol}
                        </div>
                      </div>
                    );
                  })}
                {passengerPrices.map((price, key) => {
                  return (
                    <div className={s.price} key={key}>
                      <div className={s.text}>
                        {`${t('passenger')} ${key + 1}`}
                      </div>
                      <div className={s.description}>
                        {/* ({`${isLounge ? 'Lounge' : 'Regular'}`}) */}
                      </div>
                      <span>{`${price} ${symbol}`}</span>
                    </div>
                  );
                })}
                {!!passengersFee && (
                  <div className={s.price}>
                    <div className={s.text}>{t('service fee')}</div>
                    <span>{`${passengersFee} ${symbol}`}</span>
                  </div>
                )}
                {page === 'transfer'
                  ? totalTransferPrice !== null && (
                      <div className={s.price}>
                        <div className={s.text}>{t('taxi transfer')}</div>
                        {}
                        <span>{`${totalTransferPrice} ${symbol}`}</span>
                      </div>
                    )
                  : !!basketTransfers.length && (
                      <div className={s.price}>
                        <div className={s.text}>{t('taxi transfer')}</div>
                        {}
                        <span>{`${basketTransfersPrice} ${symbol}`}</span>
                      </div>
                    )}
                {page === 'transfer' ? (
                  <h4 className={cn(s.price, s.total)}>
                    {t('total')}
                    <span>{`${
                      totalTransferPrice !== null
                        ? +totalTransferPrice +
                          +totalPassPrice +
                          +passengersFee -
                          +vouchers.totalVouchersValue
                        : +totalPassPrice +
                          +passengersFee -
                          +vouchers.totalVouchersValue
                    } ${symbol}`}</span>
                  </h4>
                ) : (
                  <h4 className={cn(s.price, s.total)}>
                    {t('total')}
                    <span>
                      {totalPrice > vouchers.totalVouchersValue
                        ? totalPrice - vouchers.totalVouchersValue
                        : 0}{' '}
                      {symbol}
                    </span>
                  </h4>
                )}
              </div>
            </>
          )
        : null}
      {!notRenderNextButton && (
        <Button
          disabled={loading || disabledNextStep}
          className={s.button}
          onClick={handleContinue}
        >
          {loading ? <Loading /> : t('continue')}
        </Button>
      )}
    </div>
  );
};

const TripDetails = ({
  t,
  i18n,
  history,
  nextStep,
  onContinueClick = null,
  notRenderNextButton,
  disabledNextStep = true,
  loading,
  getTickets,
  variables,
  totalTransferPrice = null,
  page,
  className,
  ...props
}) => {
  const [state, dispatch] = useContext(StoreContext);
  const { language } = i18n;
  const {
    symbol,
    totalPrice,
    basketValidUntil,
    isSomethingWrong,
    isBasketExrpired,
    isSomethingWrongMessage,
    isSomethingWrongTitle,
    passengerPrices,
    passengersFee,
    vouchers,
  } = state.changeTickets;
  const nextButtonRef = useRef(null);
  const { isLapUp } = useContext(WidthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenExpiredBasket, setIsOpenExpiredBasket] = useState(true);
  const [isOpenBasketTime, setIsOpenBasketTime] = useState(false);
  const [isOpenInformation, setIsOpenInformation] = useState(true);
  const [deleteBasket] = useMutation(DELETE_BASKET);

  const totalPassPrice = passengerPrices.reduce((sum, price) => {
    return sum + price;
  }, 0);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const toggleBasketTimeModal = () => {
    setIsOpenBasketTime(!isOpenBasketTime);
  };

  const toggleInformationModal = () => {
    dispatch({
      type: 'UPDATE_CHANGE_TICKETS',
      payload: {
        isSomethingWrongTitle: '',
        isSomethingWrongMessage: '',
      },
    });
  };

  const toggleExpiredBasketModal = () => {
    dispatch({
      type: 'UPDATE_CHANGE_TICKETS',
      payload: {
        isBasketExrpired: false,
        isSomethingWrong: false,
      },
    });
    // setIsOpenExpiredBasket(!isOpenExpiredBasket);
  };

  const handleContinue = () => {
    if (onContinueClick) {
      onContinueClick();
    } else {
      history.push(
        language === DEFAULT_LANG
          ? `/change-tickets/${nextStep}/`
          : `/${language}/change-tickets/${nextStep}/`
      );
    }
  };

  useEffect(() => {
    if (isOpen) {
      // TODO: check this
      document.body.style.height = '100vh';
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.height = 'inherit';
      document.body.style.overflow = 'inherit';
    }

    if (isLapUp) {
      document.body.style.height = 'inherit';
      document.body.style.overflow = 'inherit';
    }
  }, [isOpen, isLapUp]);

  useEffect(() => {
    // const INFORM_IN_SEC = 120;

    const interval = setInterval(() => {
      // const isLessTwoMin =
      //   (+getTime(new Date(basketValidUntil)) - +getTime(new Date())) /
      //     1000 <
      //   INFORM_IN_SEC;

      const isTimeIsUp =
        +getTime(new Date(basketValidUntil)) - +getTime(new Date()) < 0;

      // console.log('isLessTwoMin', isLessTwoMin);
      // if (isLessTwoMin) {
      //   setIsOpenBasketTime(true);
      // }

      // if (isTimeIsUp) {
      //   clearInterval(interval);
      //   // deleteBasket();
      //   // setIsOpenBasketTime(false);
      //   history.push(language === DEFAULT_LANG ? `/` : `/${language}/`);

      //   dispatch({
      //     type: 'RESET_CHANGE_TICKETS',
      //   });
      // }
    }, 1000);

    return () => clearInterval(interval);
  }, [basketValidUntil]);

  return (
    <>
      {isSomethingWrongMessage && isSomethingWrongTitle && (
        <Information
          isOpen={isOpenInformation}
          toggleModal={toggleInformationModal}
        />
      )}
      {/* <UpdateBasketTime
        isOpen={isOpenBasketTime}
        toggleModal={toggleBasketTimeModal}
      />
      {(isBasketExrpired || isSomethingWrong) && (
        <BasketExpired
          isOpen={isOpenExpiredBasket}
          toggleModal={toggleExpiredBasketModal}
          isBasketExrpired={isBasketExrpired}
        />
      )} */}
      <TripDetailsContent
        t={t}
        isMobile={false}
        {...props}
        language={language}
        getTickets={getTickets}
        variables={variables}
        className={cn(s.default, className)}
        notRenderNextButton={notRenderNextButton}
        disabledNextStep={disabledNextStep}
        handleContinue={handleContinue}
        loading={loading}
        nextButtonRef={nextButtonRef}
        totalTransferPrice={totalTransferPrice}
        page={page}
        totalPassPrice={totalPassPrice}
      />
      <Container className={cn(s.tripDetailsFixed, isOpen && s.open)}>
        <Container full={false}>
          <TripDetailsContent
            t={t}
            isMobile
            {...props}
            page={page}
            language={language}
            getTickets={getTickets}
            variables={variables}
            handleContinue={handleContinue}
            notRenderNextButton={notRenderNextButton}
            disabledNextStep={disabledNextStep}
            className={cn(s.fixed, isOpen && s.open)}
            totalTransferPrice={totalTransferPrice}
            totalPassPrice={totalPassPrice}
          />
          <section className={s.section}>
            {!vouchers.showVoucherInput && (
              <div className={s.total} onClick={toggleOpen}>
                <i className={i.arrowBottom} />
                {page === 'transfer' ? (
                  <div className={s.price}>
                    <span>{t('total')}</span>
                    <div>{`${
                      totalTransferPrice !== null
                        ? +totalTransferPrice + +totalPassPrice + +passengersFee
                        : +totalPassPrice + +passengersFee
                    } ${symbol}`}</div>
                  </div>
                ) : (
                  <div className={s.price}>
                    <span>{t('total')}</span>
                    <div>
                      {totalPrice > vouchers.totalVouchersValue
                        ? totalPrice - vouchers.totalVouchersValue
                        : 0}{' '}
                      {symbol}
                    </div>
                  </div>
                )}
              </div>
            )}
            {page === 'payment' && !vouchers.fullyСoversСost && (
              <ChangeVoucher />
            )}
            <Countdown
              hours={false}
              days={false}
              className={cn(
                s.countdown,
                (!basketValidUntil || isOpen) && s.hide
              )}
              timestamp={
                basketValidUntil ? getTime(new Date(basketValidUntil)) : 0
              }
            />
            {!notRenderNextButton && (
              <Button
                disabled={loading || disabledNextStep}
                onClick={handleContinue}
                className={s.button}
              >
                {loading ? <Loading /> : t('continue')}
              </Button>
            )}
          </section>
        </Container>
      </Container>
    </>
  );
};

export default withRouter(withTranslation()(TripDetails));
