/* eslint-disable */
import React, { Children, cloneElement } from 'react';
import cn from 'classnames';
import s from './dropdown.module.scss';

const DropdownItem = ({
  className,
  children,
  disabled = false,
  onClick = () => {},
  callback = () => {},
  isOpen,
}) => {
  const childrens = Children.toArray(children);
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        if (!disabled) {
          onClick();
          callback();
        }
      }}
      disabled={disabled}
      className={cn(s.option, className)}
    >
      {childrens.map((child) =>
        cloneElement(child, {
          isOpen,
        })
      )}
    </div>
  );
};

export default DropdownItem;
