/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import addMinutes from 'date-fns/addMinutes';
import {
  Container,
  Link,
  Button,
  LazyLoadImage,
  ResultsOutOfDate,
  SelectPriceClass,
  Dropdown,
  DropdownOption,
} from '@lux/ui';
import { StoreContext } from '@lux/spa/store';
import { useMutation } from '@apollo/react-hooks';
import { localizedDate, sortTickets } from 'utils';
import { sortTicketOptions } from '@lux/spa/config';
import { WidthContext } from '@lux/spa/contexts/width-context';
import {
  ChangeTicket,
  ChangeTicketsSearch,
  TripDetailsChange,
} from '@lux/spa/components';
import { MODIFICATION_SEARCH } from '@lux/spa/apollo/mutations';
import i from '@lux/spa/styles/icons.module.scss';
import { TicketsNotFound, TicketsNoResultsYet } from './components';
import s from './search.module.scss';
import { getAvailableTickets } from './helpers';

const SearchTickets = ({ i18n, t, className, history, match }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { isLapUp, isMob, isTabDown } = useContext(WidthContext);
  const { language } = i18n;
  const { currency } = state.changeTickets;
  const [lists, setLists] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [sortBy, setSortBy] = useState(sortTicketOptions.transfers.id);
  const [isLoaded, setIsLoaded] = useState(false);

  const { changeTickets = {} } = state;
  

  const { search = {}, isOpenBlind } = changeTickets;

  const {
    from = {},
    to = {},
    departDate,
    basketCode,
    basketId,
    isOutbound,
    productCodes,
    passengers,
    isSearchOutOfDate,
    promocode,
  } = search;

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSelectPrice, setIsOpenSelectPrice] = useState(false);

  const toggleModalSelectPrice = () => {
    setIsOpenSelectPrice(!isOpenSelectPrice);
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
    dispatch({
      type: 'UPDATE_CHANGE_TICKETS',
      payload: {
        isSearchOutOfDate: false,
      },
    });
  };

  const toggleOpenBlind = () => {
    dispatch({
      type: 'UPDATE_CHANGE_TICKETS',
      payload: {
        isOpenBlind: !isOpenBlind,
      },
    });
  };

  const variables = {
    BasketCode: basketCode,
    BasketId: basketId,
    Currency: 'CURRENCY.RUB',
    DepartureDate: departDate,
    DestinationBusStopId: +to.BusStopId,
    IsOutbound: isOutbound,
    OriginBusStopId: +from.BusStopId,
    ProductCodes: productCodes,
    lang: language,
    CampaignCode: promocode,
  };

  const [getTickets, { loading, data, called }] = useMutation(
    MODIFICATION_SEARCH,
    {
      onCompleted: data => {
        const { error } = data.modificationSearch;

        if (error) {
          toast.error(error.ErrorReason);
        } else {
          const tickets = getAvailableTickets(data?.modificationSearch?.results?.Journeys || []);

          setTickets(tickets);

          setIsLoaded(true);
          // msi - modification session id
          // localStorage.setItem(
          //   'msi',
          //   data?.modificationSearch?.session?.SessionId
          // );
          if (data?.modificationSearch?.session?.SessionId) {
            const maxAgeCookie = 2147483647; // 2147483647 ~ 68 years
            document.cookie = `msi=${data?.modificationSearch?.session?.SessionId};path=/;max-age=${maxAgeCookie};`;
          }

          let basketValidUntil =
            data?.modificationSearch?.session?.SessionExpiresAtUtc;

          const currentDate = new Date();
          const timezoneOffsetInMinutes = currentDate.getTimezoneOffset();

          basketValidUntil = addMinutes(
            new Date(basketValidUntil),
            timezoneOffsetInMinutes * -1
          );

          dispatch({
            type: 'UPDATE_CHANGE_TICKETS',
            payload: {
              basketValidUntil,
            },
          });
        }
      },
      onError: data => {
        toast.error(t('something wrong'));
      },
    }
  );

  const resetTickets = () => {
    dispatch({
      type: 'RESET_CHANGE_TICKETS',
    });
  };

  useEffect(() => {
    resetTickets();
    getTickets({ variables });
  }, [currency]);

  // let tickets = data?.modificationSearch?.results?.Journeys || [];
  // tickets = tickets.filter(t => t.IsForSale);

  useEffect(() => {
    setIsOpen(isSearchOutOfDate);
  }, [isSearchOutOfDate]);

  useEffect(() => {
    sortTickets(tickets, setLists, sortBy);
  }, [sortBy, tickets]);

  return (
    <div className={cx(s.searchTickets, className)}>
      {/* <ResultsOutOfDate
        isOpen={isOpen}
        toggleModal={toggleModal}
        getTickets={getTickets}
        variables={variables}
      /> */}
      {isOpenSelectPrice && (
        <SelectPriceClass
          isOpen={isOpenSelectPrice}
          toggleModal={toggleModalSelectPrice}
        />
      )}
      <Container className={cx(s.blind, isOpenBlind && s.open)}>
        <Container className={s.container} full={false}>
          <section>
            <div className={s.header}>
              <Link to="/">
                <LazyLoadImage src="/images/svg/logo-grey.svg" alt="" />
              </Link>
              <div>
                <i className={cx(i.close, s.close)} onClick={toggleOpenBlind} />
              </div>
            </div>
            {!isLapUp && (
              <ChangeTicketsSearch
                className={s.searchForm}
                inputsClassName={s.inputs}
                ButtonSearch={({ pushAnaliticsToGTM, ...props }) => (
                  <Button
                    {...props}
                    className={s.searchButton}
                    onClick={() => {
                      // pushAnaliticsToGTM();
                      getTickets({ variables });
                      toggleOpenBlind();
                    }}
                    text={t('search')}
                  />
                )}
              />
            )}
          </section>
        </Container>
      </Container>
      <Container className={s.searchSection}>
        <Container full={false}>
          <section>
            <ChangeTicketsSearch
              className={s.searchForm}
              ButtonSearch={({ pushAnaliticsToGTM, ...props }) => (
                <Button
                  {...props}
                  className={s.searchButton}
                  onClick={() => {
                    // pushAnaliticsToGTM();
                    getTickets({ variables });
                  }}
                  text={t('search')}
                />
              )}
            />
            <div className={s.searchPanel}>
              <div className={s.searchInfo}>
                <div className={s.directions}>
                  <div>{from.BusStopName}</div>
                  <span>{'>'}</span>
                  <div>{to.BusStopName}</div>
                </div>
                {departDate && (
                  <div className={s.result}>
                    {departDate && localizedDate(departDate, 'd LLL, eee')}
                    {`, ${passengers} `}
                    {passengers > 1 ? t('adults') : t('adult')}
                  </div>
                )}
              </div>
              <div className={s.showSearch} onClick={toggleOpenBlind}>
                <i className={i.search} />
              </div>
            </div>
          </section>
        </Container>
      </Container>
      <Container className={s.tickets}>
        <Container full={false}>
          <section className={s.ticketsSection}>
            <h1>{t('select ticket')}</h1>
            <div className={s.content}>
              <div className={s.ticketsList}>
                {loading ? (
                  <div className={cx(s.ticketsHeader)}>
                    <div className={s.ticketsTitle}>
                      <div className={s.titleText}>
                        {t("searching")}...
                      </div>
                    </div>
                  </div>
                ) : lists.length ? (
                  <>
                    {lists.map((list, key) => {
                      const tickets = list.tickets;

                      const withTransfer = tickets?.[0]?.Legs?.length > 1;

                      return (
                        <>
                          <div className={s.ticketsHeader}>
                            <div className={s.ticketsTitle}>
                              <div className={s.countText}>
                                {tickets.length}
                              </div>
                              <div className={s.titleText}>
                                {[
                                  sortTicketOptions.time.id,
                                  sortTicketOptions.price.id,
                                ].includes(sortBy)
                                  ? t("routes")
                                  : withTransfer
                                    ? t("routes with transfers")
                                    : t("direct routes")}
                              </div>
                            </div>
                            {key === 0 && (
                              <div className={s.sort}>
                                <div className={s.text}>
                                  {t("sorted by")}
                                </div>
                                <Dropdown
                                  smallMenu
                                  isRight={!isLapUp}
                                  className={s.targetWrap}
                                  Target={(props) => {
                                    return (
                                      <div {...props}>
                                        <div className={s.target}>
                                          <div className={s.selectedSort}>
                                            {t(
                                              sortTicketOptions[sortBy]
                                                .translateKey,
                                            )}
                                          </div>
                                          <div
                                            className={cx(
                                              s.arrowWrap,
                                              props.isOpen && s.open,
                                            )}
                                          >
                                            <i className={cx(i.arrowBottom, s.arrow)} />
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }}
                                >
                                  {Object.values(sortTicketOptions).map(
                                    (option) => {
                                      return (
                                        <DropdownOption
                                          className={s.dropdownOption}
                                          callback={() => {
                                            setSortBy(option.id);
                                          }}
                                        >
                                          <div className={s.iconWrap}>
                                            {option.id === sortBy && (
                                              <div className={s.checkmark} />
                                            )}
                                          </div>
                                          <div className={s.action}>
                                            {t(option.translateKey)}
                                          </div>
                                        </DropdownOption>
                                      );
                                    },
                                  )}
                                </Dropdown>
                              </div>
                            )}
                          </div>
                          {tickets.map((ticket, key) => {
                            return (
                              <ChangeTicket
                                toggleModalSelectPrice={toggleModalSelectPrice}
                                isOpenSelectPrice={isOpenSelectPrice}
                                ticket={ticket}
                                notifications={ticket.Notifications}
                                passengers={passengers}
                                isModifiableJourney={ticket.IsModifiableJourney}
                                arrivalDateTimeTimeZone={ticket.ArrivalDateTimeTimeZone}
                                departureDateTimeTimeZone={
                                  ticket.DepartureDateTimeTimeZone
                                }
                                key={ticket.JourneyId}
                                ticketLegs={ticket.Legs}
                                isMob={isMob}
                                hasEquipment={false}
                                transfers={ticket.Legs.length - 1}
                                journeyId={ticket.JourneyId}
                                campaignPrice={ticket.CampaignPrice}
                                businessCampaignPrice={ticket.BusinessCampaignPrice}
                                originStopName={ticket.OriginStopName}
                                destinationStopName={ticket.DestinationStopName}
                                availableRegularSeats={ticket.AvailableRegularSeats}
                                availableBusinessClassSeats={
                                  ticket.AvailableBusinessClassSeats
                                }
                                regularPrice={ticket.RegularPrice}
                                businessClassPrice={ticket.BusinessClassPrice}
                                durationTravel={ticket.Duration}
                                departureDateTime={ticket.DepartureDateTime}
                                arrivalDateTime={ticket.ArrivalDateTime}
                                availableCampaignSeats={ticket.AvailableCampaignSeats}
                                availableBusinessCampaignSeats={
                                  ticket.availableBusinessCampaignSeats
                                }
                              />
                            );
                          })}
                        </>
                      )
                    })}
                    {/* {tickets.map(ticket => {
                      return (
                        <ChangeTicket
                          toggleModalSelectPrice={toggleModalSelectPrice}
                          isOpenSelectPrice={isOpenSelectPrice}
                          ticket={ticket}
                          notifications={ticket.Notifications}
                          passengers={passengers}
                          isModifiableJourney={ticket.IsModifiableJourney}
                          arrivalDateTimeTimeZone={ticket.ArrivalDateTimeTimeZone}
                          departureDateTimeTimeZone={
                            ticket.DepartureDateTimeTimeZone
                          }
                          key={ticket.JourneyId}
                          ticketLegs={ticket.Legs}
                          isMob={isMob}
                          hasEquipment={false}
                          transfers={ticket.Legs.length - 1}
                          journeyId={ticket.JourneyId}
                          campaignPrice={ticket.CampaignPrice}
                          businessCampaignPrice={ticket.BusinessCampaignPrice}
                          originStopName={ticket.OriginStopName}
                          destinationStopName={ticket.DestinationStopName}
                          availableRegularSeats={ticket.AvailableRegularSeats}
                          availableBusinessClassSeats={
                            ticket.AvailableBusinessClassSeats
                          }
                          regularPrice={ticket.RegularPrice}
                          businessClassPrice={ticket.BusinessClassPrice}
                          durationTravel={ticket.Duration}
                          departureDateTime={ticket.DepartureDateTime}
                          arrivalDateTime={ticket.ArrivalDateTime}
                          availableCampaignSeats={ticket.AvailableCampaignSeats}
                          availableBusinessCampaignSeats={
                            ticket.availableBusinessCampaignSeats
                          }
                        />
                      );
                    })} */}
                  </>
                ) : called ? (
                  <TicketsNotFound from={from} to={to} />
                ) : (
                  <TicketsNoResultsYet from={from} to={to} />
                )}
              </div>
              {isTabDown ? (
                <TripDetailsChange
                  isSearch
                  notRenderNextButton
                  getTickets={getTickets}
                  variables={variables}
                />
              ) : (
                <div className={s.tripDetails}>
                  <TripDetailsChange
                    isSearch
                    notRenderNextButton
                    getTickets={getTickets}
                    variables={variables}
                    className={s.aside}
                  />
                </div>
              )}
            </div>
          </section>
        </Container>
      </Container>
    </div>
  );
};

export default withRouter(withTranslation()(SearchTickets));
