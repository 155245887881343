/* eslint-disable */
import isAfter from 'date-fns/isAfter';

export const isSoldOut = value =>
  value === 'NOT_FOR_SALE_REASON.SOLD_OUT' ||
  value === 'NOT_FOR_SALE_REASON.NOT_ENOUGH_SEATS';

export const getAvailableTickets = (searchResults = [], outbound = {}) => {
  let tickets = searchResults.filter(
    t => t.IsForSale || isSoldOut(t?.NotForSaleReasonType)
  );

  if (outbound.selected) {
    tickets = searchResults.filter(ticket =>
      isAfter(
        new Date(ticket.DepartureDateTime),
        new Date(outbound.arrivalDateTime)
      )
    );
  }

  return tickets;
};
